.headerMobileNav {
  display: none;
}

@media screen and (max-width: 1100px) {
  .login_section{
    display: none;
  }
  .headerMobileNav {
    display: block;
    width: 3rem;

    .btn {
      display: block;
      background-color: #a243b5;
      cursor: pointer;
      margin: 0 auto;
    }

    .mobile_nav_markList {
      opacity: 0;
      height: 0;
      position: fixed;
      top: 64px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease;
      z-index: 999;
      overflow-y: auto;
    }

    .isShow {
      height: auto;
      opacity: 1;
    }
  }
}


@media screen and (max-width: 600px) {
  .login_section{
    display: none;
  }
}
